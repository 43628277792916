/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, createFilterOptions, IconButton, MenuItem } from '@mui/material';
import { ICellEditorParams } from 'ag-grid-community';
import { resourceRus } from 'api/references/estimates/estimates.types';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  getPricesState,
  removeGroup,
  removeUnit,
} from 'store/slices/references/prices/prices';
import { UnitWeight } from 'store/slices/references/prices/types';
// import { modifyValue, PopperComponent } from '../../useTableData';
// import { PRNumericField, StyledAutocomplete } from '../PricesReferences.style';
import { ICalculationDictionary } from 'api/calculationDictionary';
import {
  useDeleteKFOGroupMutation,
  useDeleteUnitWeightMutation,
} from 'api/references/estimates';
import { PRStyledBaseTextField } from 'components/BaseTextField/BaseTextField.styles';
import { useAppDispatch } from 'store/store';
import {
  PRNumericField,
  StyledAutocomplete,
} from '../../../../../../../Administration/AdminReferences/Prices/components/PricesReferences.style';
import {
  modifyValue,
  PopperComponent,
} from '../../../../../../../Administration/AdminReferences/Prices/useTableData';
import { PRStyledSelect } from '../../../../../Accomplishment/components/CaptionTable/CaptionTable.style';

const filterUnit = createFilterOptions<UnitWeight & { label: string }>();
const filterGroup = createFilterOptions<
  { title: string; id: number } & { label: string }
>();
const disabledFields = ['MiM', 'WORKERS', 'MACHINE_OPERATORS'];
const requiredForCenterAlign = ['qtyPerUnit', 'qtyTotal'];
const requiredNamesForNum = [
  'costPerUnit',
  'fotPerUnit',
  'kfoCoef',
  'kzPerUnit',
  'mimPerUnit',
  'mtrPerUnit',
  'qtyPerUnit',
  'qtyTotal',
  'tzPerUnit',
  'tzmPerUnit',
  'workTimePerUnit',
  'costTotal',
  'fotTotal',
  'kzTotal',
  'mimTotal',
  'mtrTotal',
  'total',
  'totalPerUnit',
  'tzTotal',
  'tzmTotal',
  'workTimeTotal',
  'qtyPerUnit',
  'qtyTotal',
  'base.fotPerUnit',
  'base.mimPerUnit',
  'base.mtrPerUnit',
];
const requiredNamesForNumExclude: string[] = [];

const CellEditor = forwardRef<
  any,
  ICellEditorParams<ICalculationDictionary, any, any>
>((props, ref) => {
  const { unitList, groupList } = useSelector(getPricesState);
  // const { deleteUnit, deleteGroup } = useContext(PricesReferenceContext);

  const dispatch = useAppDispatch();

  const [value, setValue] = useState<string>('');
  // resourceRus[props.value?.toString()?.replace(/\./, ',') as keyof typeof resourceRus] || '',
  const [deleteUnit] = useDeleteUnitWeightMutation();

  /**
   * Функция удаления еденицы измерения
   */
  const deleteUnitWeight = useCallback(
    (idUnit: number) => {
      deleteUnit({ unitID: idUnit }).then(() => {
        dispatch(removeUnit(idUnit));
      });
    },
    [unitList],
  );

  const [deleteGroup] = useDeleteKFOGroupMutation();
  /**
   * Функция удаления группы КФО
   */
  const deleteGroupKFO = useCallback(
    (idGroup: number) => {
      console.log('removed group');
      deleteGroup({ groupID: idGroup }).then((resp) => {
        console.log(resp);
        dispatch(removeGroup(idGroup));
      });
    },
    [groupList],
  );

  useEffect(() => {
    if (requiredNamesForNumExclude.includes(props.colDef.field as string)) {
      const v = modifyValue(props.value) ?? '';
      // const v = props.value?.toString()?.replace('.', ',');
      console.log('edit1>>', v);
      setValue(v);
    } else {
      if (props.colDef.field === 'resourceType') {
        // setValue(resourceRus[props.value as keyof typeof resourceRus] || '');
        console.log('edit2>>', props.value);

        setValue(
          resourceRus[props.value as keyof typeof resourceRus] || props.value,
        );
      } else if (props.colDef.field === 'kfoCoef') {
        console.log('edit3>>', props.data!.kfoCoef);

        setValue(
          modifyValue(
            props.data!.kfoCoef ? Number(props.data.kfoCoef) : 0,
            false,
          ) || '',
        );
      } else if (props.colDef.field === 'unit') {
        console.log('edit4>>', props.data!.unit!);

        setValue(props.data!.unit!);
      } else if (props.colDef.field === 'qtyPerUnit') {
        if (props.data.rowType === 'RATE') {
          console.log('edit6>>', props.data?.qtyTotal);
          setValue(
            props.data?.qtyTotal
              ? (modifyValue(+props.data?.qtyTotal!, false) ?? '')
              : '',
          );
        } else {
          console.log('edit7>>', props.data?.qtyPerUnit);

          setValue(
            props.data?.qtyPerUnit
              ? (modifyValue(+props.data?.qtyPerUnit!, false) ?? '')
              : '',
          );
        }
      } else {
        console.log('edit8>>', props.value);
        console.log(props.value, modifyValue(+props.value));
        if (requiredNamesForNum.includes(props.colDef?.field as string)) {
          setValue(modifyValue(+props.value) || '');
        } else {
          setValue(props.value || '');
        }
      }
    }
  }, [props]);
  // const { control: controlRate, setValue: setValueRate } = useForm<FormPriceReference>();
  // const { control: controlPosition, setValue: setValuePosition } = useForm<FormPriceReferencePosition>();

  const {
    control,
    setValue: setFormValue,
    formState,
  } = useForm<{ input: string }>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      input: '',
    },
  });

  useImperativeHandle(
    ref,
    () => ({
      getValue: () => {
        const copyValue = value;
        if (requiredNamesForNum.includes(props.colDef.field as string)) {
          return value === ''
            ? null
            : (Number(
                value?.toString()?.replace?.(/\s/g, '')?.replace?.(/,/g, '.'),
              ) ?? null);
          // if (props.colDef.field === 'unit') {
          //   return valReplaced||null;
          // } else if (props.colDef.field === 'pz') {
          //   return { ...copyValue, input0: valReplaced || null };
          // } else {
          //   return { ...copyValue, input0: copyValue.input0 === '' ? null : valReplaced ?? null };
          // }
        }
        return copyValue;
      },
      isCancelAfterEnd() {
        return !formState.isValid && !formState.isDirty;
      },
    }),
    [formState.isValid, value],
  );
  useEffect(() => {
    if (props.colDef.field !== 'unit') {
      props?.eGridCell?.getElementsByTagName('input')[0]?.select();
      // props?.eGridCell?.getElementsByTagName('input')[0]?.focus();
    }
    // }
  }, [props]);

  const isKFOGroup = useMemo(() => {
    return props.colDef?.field === 'kfoGroup';
  }, [props]);

  const deleteItem = isKFOGroup ? deleteGroupKFO : deleteUnitWeight;

  const renderIcon = useCallback(
    (id: number) => {
      return isKFOGroup ? (
        <IconButton
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            deleteItem(id);
          }}>
          <DeleteOutlineIcon fontSize={'small'} />
        </IconButton>
      ) : (
        <span />
      );
    },
    [isKFOGroup, deleteItem],
  );

  switch (props.colDef.field) {
    case 'kfoGroup': {
      const refPopperUnit = useRef<HTMLDivElement | null>(null);
      const refPopperQTY = useRef<HTMLInputElement | null>(null);
      // const deleteGroupOrUnit = isKFOGroup ? deleteGroup : deleteUnit;
      const optionList = isKFOGroup
        ? groupList.map((_) => ({ ..._, name: _.title }))
        : unitList;
      // switch (props.data?.rowType) {
      //   case 'RATE':
      return (
        <Box
          display={'flex'}
          alignItems={'center'}
          /*p={'0 8px'}*/ width={'100%'}
          height={'100%'}>
          <Controller
            control={control as any}
            name={'input'}
            rules={{
              validate: {
                maxLength(v) {
                  if (v && v?.length > 20) {
                    return 'Максимум 20 символов';
                  }
                  return true;
                },
                validateField(v) {
                  if (
                    v &&
                    /[^a-zA-Zа-яА-ЯёЁ0-9\s!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/gi.test(
                      v,
                    )
                  ) {
                    return 'Только клавиатурные символы';
                  }
                  return true;
                },
              },
            }}
            render={({ field: { ref, ...propsController }, formState }) => {
              const errorMessage = formState.errors?.input?.message || '';
              return (
                <>
                  <StyledAutocomplete
                    ref={refPopperUnit}
                    itemRef={refPopperUnit.current as any}
                    {...propsController}
                    sx={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    options={optionList.map((_) => ({ ..._, label: _.name }))}
                    // onSelect={props.onChange}
                    aria-valuemax={20}
                    filterOptions={(options: any[], params) => {
                      const filtered = isKFOGroup
                        ? filterGroup(options, params)
                        : filterUnit(options, params);
                      const { inputValue } = params;
                      const isExisting = options.some(
                        (option) => inputValue === option.name,
                      );
                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          id: -1,
                          name: 'Нет совпадений',
                          label: 'Нет совпадений',
                        } as any);
                      }
                      return filtered;
                    }}
                    componentsProps={{
                      popper: {
                        modifiers: [
                          { name: 'offset', options: { offset: [-15, 0] } },
                        ],
                      },
                      paper: {
                        sx: {
                          width: 250,
                        },
                      },
                    }}
                    value={value || ''}
                    inputValue={value || ''}
                    freeSolo={true}
                    noOptionsText="Будет создана новая запись"
                    disableClearable={true}
                    clearOnBlur={false}
                    autoSelect={false}
                    // onChange={(event, val) => {
                    //   if (typeof val === 'string') {
                    //     let v = val;
                    //     if (props.colDef.field === 'kfoGroup' && v) {
                    //       v = v?.toLowerCase();
                    //     }
                    //     setValue(v);
                    //     setFormValue('input', value, { shouldValidate: true });
                    //   }
                    // }}
                    autoFocus
                    onBlur={() => props.stopEditing(true)}
                    // onBlur={() => !formState.isDirty && props.stopEditing(true)}
                    openOnFocus={true}
                    onHighlightChange={(event, option, reason) => {}}
                    // onBlur={() => {
                    //   props.onChange(value === '' ? props.value : value);
                    // }}
                    // onClose={() => {
                    //   props.onChange(value);
                    // }}
                    onInputChange={(event, val) => {
                      if (typeof val === 'string') {
                        let v = val;
                        // if (props.colDef.field === 'kfoGroup' && v) {
                        //   v = v?.toLowerCase();
                        // }
                        setValue(v);
                        setFormValue('input', v, { shouldValidate: true });
                      }
                    }}
                    renderInput={(p) => (
                      <PRStyledBaseTextField
                        {...p}
                        hiddenLabel
                        placeholder={'Не выбрано'}
                        $textAlign={'center'}
                        autoFocus
                        InputProps={{
                          ...p.InputProps,
                          ...(!!errorMessage && {
                            sx: { background: '#FFB1B126 !important' },
                          }),
                        }}
                        inputProps={{
                          ...p?.inputProps,
                        }}
                        size="small"
                        error={!!errorMessage}
                      />
                    )}
                    renderOption={(props, option: any) => {
                      return (
                        <MenuItem
                          sx={{ width: '100%' }}
                          {...props}
                          disabled={option.id < 0}
                          key={option.id}>
                          <Box
                            width={'100%'}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}>
                            <span
                              style={{
                                whiteSpace: 'break-spaces',
                                wordWrap: 'break-word',
                              }}>
                              {option.name}
                            </span>
                            {/*{isKFOGroup ? (*/}
                            {/*  <IconButton*/}
                            {/*    onClick={(event) => {*/}
                            {/*      event.preventDefault();*/}
                            {/*      event.stopPropagation();*/}
                            {/*      deleteItem(option.id);*/}
                            {/*    }}>*/}
                            {/*    <DeleteOutlineIcon fontSize={'small'} />*/}
                            {/*  </IconButton>*/}
                            {/*) : option.id > 10 ? (*/}
                            {/*  <IconButton*/}
                            {/*    onClick={(event) => {*/}
                            {/*      event.preventDefault();*/}
                            {/*      event.stopPropagation();*/}
                            {/*      deleteItem(option.id);*/}
                            {/*    }}>*/}
                            {/*    <DeleteOutlineIcon fontSize={'small'} />*/}
                            {/*  </IconButton>*/}
                            {/*) : (*/}
                            {/*  <span />*/}
                            {/*)}*/}
                          </Box>
                        </MenuItem>
                      );
                    }}
                  />
                  <PopperComponent
                    message={errorMessage as string}
                    refItem={refPopperUnit.current}
                  />
                </>
              );
            }}
          />
        </Box>
      );
    }
    case 'kfoUnit':
    case 'unit': {
      const refPopperUnit = useRef<HTMLDivElement | null>(null);
      const refPopperQTY = useRef<HTMLInputElement | null>(null);
      // const deleteGroupOrUnit = isKFOGroup ? deleteGroup : deleteUnit;
      const optionList = isKFOGroup
        ? groupList.map((_) => ({ ..._, name: _.title }))
        : unitList;
      // switch (props.data?.rowType) {
      //   case 'RATE':
      return (
        <Box
          display={'flex'}
          alignItems={'center'}
          /*p={'0 8px'}*/ width={'100%'}
          height={'100%'}>
          <Controller
            control={control as any}
            name={'input'}
            rules={{
              validate: {
                maxLength(v) {
                  if (v && v?.length > 10) {
                    return 'Максимум 10 символов';
                  }
                  return true;
                },
                validateField(v) {
                  if (
                    v &&
                    /[^a-zA-Zа-яА-ЯёЁ0-9\s!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/gi.test(
                      v,
                    )
                  ) {
                    return 'Только клавиатурные символы';
                  }
                  return true;
                },
              },
            }}
            render={({ field: { ref, ...propsController }, formState }) => {
              const errorMessage = formState.errors?.input?.message || '';
              return (
                <>
                  <StyledAutocomplete
                    ref={refPopperUnit}
                    itemRef={refPopperUnit.current as any}
                    {...propsController}
                    sx={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    options={optionList.map((_) => ({ ..._, label: _.name }))}
                    // onSelect={props.onChange}
                    aria-valuemax={10}
                    filterOptions={(options: any[], params) => {
                      const filtered = isKFOGroup
                        ? filterGroup(options, params)
                        : filterUnit(options, params);
                      const { inputValue } = params;
                      const isExisting = options.some(
                        (option) => inputValue === option.name,
                      );
                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          id: -1,
                          name: 'Нет совпадений',
                          label: 'Нет совпадений',
                        } as any);
                      }
                      return filtered;
                    }}
                    componentsProps={{
                      popper: {
                        modifiers: [
                          { name: 'offset', options: { offset: [-15, 0] } },
                        ],
                      },
                      paper: {
                        sx: {
                          width: 'min-content',
                        },
                      },
                    }}
                    value={value || ''}
                    inputValue={value || ''}
                    freeSolo={true}
                    noOptionsText="Будет создана новая запись"
                    disableClearable={true}
                    clearOnBlur={false}
                    autoSelect={false}
                    // onChange={(event, val) => {
                    //   if (typeof val === 'string') {
                    //     let v = val;
                    //     if (props.colDef.field === 'kfoGroup' && v) {
                    //       v = v?.toLowerCase();
                    //     }
                    //     setValue(v);
                    //     setFormValue('input', value, { shouldValidate: true });
                    //   }
                    // }}
                    autoFocus
                    onBlur={() => props.stopEditing(true)}
                    // onBlur={() => !formState.isDirty && props.stopEditing(true)}
                    openOnFocus={true}
                    onHighlightChange={(event, option, reason) => {}}
                    // onBlur={() => {
                    //   props.onChange(value === '' ? props.value : value);
                    // }}
                    // onClose={() => {
                    //   props.onChange(value);
                    // }}
                    onInputChange={(event, val) => {
                      if (typeof val === 'string') {
                        let v = val;
                        // if (props.colDef.field === 'kfoGroup' && v) {
                        //   v = v?.toLowerCase();
                        // }
                        setValue(v);
                        setFormValue('input', v, { shouldValidate: true });
                      }
                    }}
                    renderInput={(p) => (
                      <PRStyledBaseTextField
                        {...p}
                        hiddenLabel
                        placeholder={'Не выбрано'}
                        $textAlign={'center'}
                        autoFocus
                        InputProps={{
                          ...p.InputProps,
                          ...(!!errorMessage && {
                            sx: { background: '#FFB1B126 !important' },
                          }),
                        }}
                        inputProps={{
                          ...p?.inputProps,
                        }}
                        size="small"
                        error={!!errorMessage}
                      />
                    )}
                    renderOption={(props, option: any) => {
                      return (
                        <MenuItem
                          sx={{ width: '100%' }}
                          {...props}
                          disabled={option.id < 0}
                          key={option.id}>
                          <Box
                            width={'100%'}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}>
                            <span
                              style={{
                                whiteSpace: 'break-spaces',
                                wordWrap: 'break-word',
                              }}>
                              {option.name}
                            </span>
                            {/*{isKFOGroup ? (*/}
                            {/*  <IconButton*/}
                            {/*    onClick={(event) => {*/}
                            {/*      event.preventDefault();*/}
                            {/*      event.stopPropagation();*/}
                            {/*      deleteItem(option.id);*/}
                            {/*    }}>*/}
                            {/*    <DeleteOutlineIcon fontSize={'small'} />*/}
                            {/*  </IconButton>*/}
                            {/*) : option.id > 10 ? (*/}
                            {/*  <IconButton*/}
                            {/*    onClick={(event) => {*/}
                            {/*      event.preventDefault();*/}
                            {/*      event.stopPropagation();*/}
                            {/*      deleteItem(option.id);*/}
                            {/*    }}>*/}
                            {/*    <DeleteOutlineIcon fontSize={'small'} />*/}
                            {/*  </IconButton>*/}
                            {/*) : (*/}
                            {/*  <span />*/}
                            {/*)}*/}
                          </Box>
                        </MenuItem>
                      );
                    }}
                  />
                  <PopperComponent
                    message={errorMessage as string}
                    refItem={refPopperUnit.current}
                  />
                </>
              );
            }}
          />
        </Box>
      );
    }
    case 'base.costPerUnit':
    case 'base.fotPerUnit':
    case 'base.kzPerUnit':
    case 'base.mimPerUnit':
    case 'base.mtrPerUnit':
    case 'base.costTotal':
    case 'base.fotTotal':
    case 'base.kzTotal':
    case 'base.mimTotal':
    case 'base.mtrTotal':
    case 'base.total':
    case 'base.totalPerUnit':
    case 'curr.costPerUnit':
    case 'curr.fotPerUnit':
    case 'curr.kzPerUnit':
    case 'curr.mimPerUnit':
    case 'curr.mtrPerUnit':
    case 'curr.costTotal':
    case 'curr.fotTotal':
    case 'curr.kzTotal':
    case 'curr.mimTotal':
    case 'curr.mtrTotal':
    case 'curr.total':
    case 'curr.totalPerUnit':
    case 'tzTotal':
    case 'tzPerUnit':
    case 'tzmPerUnit':
    case 'workTimePerUnit':
    case 'tzmTotal':
    case 'workTimeTotal': {
      const refPopper = useRef<HTMLDivElement | null>(null);
      const center = requiredForCenterAlign.includes(props.colDef.field);
      console.log(props.colDef.field);
      return (
        <Box
          // p={'2px'}
          justifyContent={'flex-start'}
          display={'flex'}
          alignItems={'center'}
          width={'100%'}
          height={'100%'}>
          <Controller
            control={control as any}
            rules={{
              validate: {
                max: (v: string) => {
                  const num = Number(
                    v?.replace?.(/\s/g, '')?.replace?.(/,/g, '.') || '0',
                  );
                  if (num > 999999999.99) {
                    return 'Максимальное значение 999 999 999,99';
                  }
                  return true;
                },
                min: (v: string) => {
                  const num = Number(
                    v?.replace?.(/\s/g, '')?.replace?.(/,/g, '.') || '0',
                  );
                  if (num < -999999999.99) {
                    return 'Минимальное значение -999 999 999,99';
                  }
                  return true;
                },
              },
            }}
            render={({
              field: { ref: refInput, ...propsController },
              formState,
            }) => {
              const errorMessage = formState.errors?.input?.message || '';
              return (
                <>
                  <PRNumericField
                    // textAlign={'center'}
                    getInputRef={(ref: any) => {
                      refPopper.current = ref;
                    }}
                    {...propsController}
                    value={value}
                    onValueChange={(event) => {
                      setValue(event.formattedValue.replace(/\./, ','));
                      setFormValue('input', event.formattedValue, {
                        shouldValidate: true,
                      });
                    }}
                    style={{ textAlign: center ? 'center' : 'right' }}
                    onFocus={(e: { target: { select: () => void } }) =>
                      e.target.select()
                    }
                    allowedDecimalSeparators={[',', '.']}
                    allowNegative={true}
                    decimalSeparator={','}
                    autoFocus={true}
                    decimalScale={2}
                    thousandSeparator={' '}
                    onBlur={() => props.stopEditing(true)}
                    // onBlur={() => !formState.isDirty && props.stopEditing(true)}
                  />
                  <PopperComponent
                    message={errorMessage as string}
                    refItem={refPopper.current}
                  />
                </>
              );
            }}
            name={'input' as any}
          />
        </Box>
      );
    }
    case 'qtyPerUnit':
    case 'qtyTotal': {
      const refPopper = useRef<HTMLDivElement | null>(null);
      const center = requiredForCenterAlign.includes(props.colDef.field);

      return (
        <Box
          // p={'2px'}
          justifyContent={'flex-start'}
          display={'flex'}
          alignItems={'center'}
          width={'100%'}
          height={'100%'}>
          <Controller
            control={control as any}
            rules={{
              validate: {
                max: (v: string) => {
                  const num = Number(
                    v?.replace?.(/\s/g, '')?.replace?.(/,/g, '.') || '0',
                  );
                  if (num > 999999999.999999) {
                    return 'Максимальное значение 999 999 999,999999';
                  }
                  return true;
                },
                min: (v: string) => {
                  const num = Number(
                    v?.replace?.(/\s/g, '')?.replace?.(/,/g, '.') || '0',
                  );
                  if (num < -999999999.999999) {
                    return 'Минимальное значение -999 999 999,999999';
                  }
                  return true;
                },
              },
            }}
            render={({
              field: { ref: refInput, ...propsController },
              formState,
            }) => {
              const errorMessage = formState.errors?.input?.message || '';
              return (
                <>
                  <PRNumericField
                    // textAlign={'center'}
                    getInputRef={(ref: any) => {
                      refPopper.current = ref;
                    }}
                    {...propsController}
                    value={value}
                    onValueChange={(event) => {
                      setValue(event.formattedValue.replace(/\./, ','));
                      setFormValue('input', event.formattedValue, {
                        shouldValidate: true,
                      });
                    }}
                    style={{ textAlign: center ? 'center' : 'right' }}
                    onFocus={(e: { target: { select: () => void } }) =>
                      e.target.select()
                    }
                    allowedDecimalSeparators={[',', '.']}
                    allowNegative={true}
                    decimalSeparator={','}
                    autoFocus={true}
                    decimalScale={6}
                    thousandSeparator={' '}
                    onBlur={() => props.stopEditing(true)}
                    // onBlur={() => !formState.isDirty && props.stopEditing(true)}
                  />
                  <PopperComponent
                    message={errorMessage as string}
                    refItem={refPopper.current}
                  />
                </>
              );
            }}
            name={'input' as any}
          />
        </Box>
      );
    }

    case 'kfoCoef': {
      const refPopper = useRef<HTMLDivElement | null>(null);

      return (
        <Box
          // p={'2px'}
          justifyContent={'flex-start'}
          display={'flex'}
          alignItems={'center'}
          width={'100%'}
          height={'100%'}>
          <Controller
            control={control as any}
            rules={{
              validate: {
                max: (v: string) => {
                  const num = Number(
                    v?.replace?.(/\s/g, '')?.replace?.(/,/g, '.') || '0',
                  );
                  if (num > 999999999.999999) {
                    return 'Максимальное значение 999 999 999,999 999';
                  }
                  return true;
                },
                min: (v: string) => {
                  const num = Number(
                    v?.replace?.(/\s/g, '')?.replace?.(/,/g, '.') || '0',
                  );
                  if (num < -999999999.999999) {
                    return 'Минимальное значение -999 999 999,999 999';
                  }
                  return true;
                },
              },
            }}
            render={({
              field: { ref: refInput, ...propsController },
              formState,
            }) => {
              const errorMessage = formState.errors?.input?.message || '';
              return (
                <>
                  <PRNumericField
                    // textAlign={'center'}
                    getInputRef={(ref: any) => {
                      refPopper.current = ref;
                    }}
                    {...propsController}
                    value={value}
                    onValueChange={(event) => {
                      setValue(event.formattedValue.replace(/\./, ','));
                      setFormValue('input', event.formattedValue, {
                        shouldValidate: true,
                      });
                    }}
                    style={{ textAlign: 'right' }}
                    onFocus={(e: { target: { select: () => void } }) =>
                      e.target.select()
                    }
                    allowedDecimalSeparators={[',', '.']}
                    allowNegative={true}
                    decimalSeparator={','}
                    autoFocus={true}
                    decimalScale={6}
                    thousandSeparator={' '}
                    onBlur={() => props.stopEditing(true)}
                    // onBlur={() => !formState.isDirty && props.stopEditing(true)}
                  />
                  <PopperComponent
                    message={errorMessage as string}
                    refItem={refPopper.current}
                  />
                </>
              );
            }}
            name={'input' as any}
          />
        </Box>
      );
    }
    case 'title': {
      const refPopper = useRef<HTMLDivElement | null>(null);
      return (
        <Box
          // p={'2px'}
          justifyContent={'flex-start'}
          display={'flex'}
          alignItems={'center'}
          width={'100%'}
          height={'100%'}>
          <Controller
            control={control as any}
            rules={{
              validate: {
                maxLength(v) {
                  if (v && (v as string).length > 300) {
                    return 'Максимально 300 символов';
                  }
                  return true;
                },
              },
            }}
            render={({
              field: { ref: refInput, ...propsController },
              formState,
            }) => {
              const errorMessage = formState.errors?.input?.message;

              return (
                <>
                  <PRStyledBaseTextField
                    error={!!formState.errors?.input}
                    inputRef={refPopper}
                    {...propsController}
                    value={value}
                    onChange={(event) => {
                      setValue(event.target.value);
                      setFormValue('input', event.target.value, {
                        shouldValidate: true,
                      });
                    }}
                    onBlur={() => !formState.isDirty && props.stopEditing(true)}
                  />
                  <PopperComponent
                    message={errorMessage as string}
                    refItem={refPopper.current}
                  />
                </>
              );
            }}
            name={'input' as any}
          />
        </Box>
      );
    }
    case 'code': {
      const refPopper = useRef<HTMLDivElement | null>(null);
      return (
        <Box
          // px={'16px'}
          // py={'2px'}
          justifyContent={'flex-start'}
          display={'flex'}
          alignItems={'center'}
          width={'100%'}
          height={'100%'}>
          <Controller
            control={control}
            rules={{
              validate: {
                maxLength(v) {
                  if (v && (v as string).length > 15) {
                    return 'Максимально 15 символов';
                  }
                  return true;
                },
                excludeSymbol(v) {
                  if (/[^a-zа-яё\-\d\s]/gi.test(v)) {
                    return 'Только буквы латиницы/кириллицы, цифры, пробел и символ “-”';
                  }
                  return true;
                },
              },
            }}
            render={({
              field: { ref: refInput, ...propsController },
              formState,
            }) => {
              const errorMessage = formState.errors?.input?.message;
              return (
                <>
                  <PRStyledBaseTextField
                    error={!!formState.errors?.input}
                    inputRef={refPopper}
                    {...propsController}
                    value={value}
                    onChange={(event) => {
                      setValue(event.target.value);
                      setFormValue('input', event.target.value, {
                        shouldValidate: true,
                      });
                    }}
                    onBlur={() => !formState.isDirty && props.stopEditing(true)}
                  />
                  <PopperComponent
                    message={errorMessage as string}
                    refItem={refPopper.current}
                  />
                </>
              );
            }}
            name={'input'}
          />
        </Box>
      );
    }
    case 'resourceType': {
      return (
        <Box
          // p={'2px'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'flex-start'}
          width={'100%'}
          height={'100%'}>
          <Controller
            control={control}
            name={'input'}
            render={({ field: { ref: refInput, ...propsController } }) => {
              return (
                <PRStyledSelect
                  ref={refInput}
                  currentValue={value}
                  {...propsController}
                  style={{ width: '100%' }}
                  nullableValues={[value, 'Не выбрано']}
                  value={value}
                  SelectProps={{
                    renderValue: () => value || 'Не выбрано',
                  }}
                  inputProps={{
                    style: {
                      textAlign: 'center',
                    },
                  }}
                  onClear={() => {}}
                  // onBlur={() => !formState.isDirty && props.stopEditing(true)}
                  onBlur={() => props.stopEditing(true)}
                  onChange={(event) => {
                    setValue(event.target.value);
                    setFormValue('input', event.target.value, {
                      shouldValidate: true,
                    });
                    setTimeout(() => {
                      props.stopEditing(true);
                    });
                  }}
                  select>
                  <MenuItem value={'Рабочие'}>Рабочие</MenuItem>
                  <MenuItem value={'Механизаторы'}>Механизаторы</MenuItem>
                  <MenuItem value={'МиМ'}>МиМ</MenuItem>
                  <MenuItem value={'Материалы'}>Материалы</MenuItem>
                  <MenuItem value={'Оборудование'}>Оборудование</MenuItem>
                  <MenuItem value={'Услуги'}>Услуги</MenuItem>
                </PRStyledSelect>
              );
            }}
          />
        </Box>
      );
    }
    default:
      return null;
  }
});
CellEditor.displayName = 'CellEditor';
export default CellEditor;
