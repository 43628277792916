import { Button, Stack, Typography } from '@mui/material';
import { GroupFolder } from 'assets/SVG/GroupFolder';
import { useCalcId } from 'hooks/useCalcId';
import { useCreateLevel } from 'pages/FullVor/Table/hooks';
import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';
import { GroupCount } from '..';
import { useTreeNodeContext } from '../../../../context';
import { getTotalCount } from './CreateLevelForm.service';
import { StyledCreateFormWrapper, StyledInput } from './CreateLevelForm.styles';
import { CreateLevelFormProps } from './CreateLevelForm.types';
import { LevelSelect } from './components';

export const CreateLevelForm: FC<CreateLevelFormProps> = ({
  childrenIds,
  groupIds,
  isHandbook,
  index
}): JSX.Element => {
  const calcID = useCalcId();
  const context = useTreeNodeContext();
  const { rowID, level, rowType, levelIds, onToggleFormVisible, hasParent } =
    context;

  const [selectedLevel, setSelectedLevel] = useState(level);
  const [title, setTitle] = useState('');
  const { createLevel } = useCreateLevel(isHandbook);
  console.log(level);
  const isLevelSelectShowed = isHandbook
    ? // @ts-ignore
      !(rowType === 'group' && level === 1) && level !== 0
    : rowType === 'folded' && level !== 0 && childrenIds[0] !== rowID;

  console.log(
    'isLevelSelectShowed',
    rowType === 'group',
    level,
    !(rowType === 'group' && level === 1)
  );

  const groupedTotalCount = useMemo(
    () => getTotalCount(rowID, level, childrenIds, groupIds),
    [rowID, level, childrenIds, groupIds]
  );

  const createLevelClickHandler = useCallback(() => {
    if (!calcID || !rowID) return;
    const indexFolder = groupIds.findIndex((g) => g === rowID);

    const neighborGroupIdx =
      indexFolder !== undefined ? groupIds[indexFolder - 1] : undefined;

    createLevel({
      rowLevel: level,
      calcID,
      title,
      rowType,
      rowID,
      level: selectedLevel,
      childrenIds: childrenIds,
      groupIds: groupIds,
      levelIds,

      upperNeighborID:
        levelIds[level] === rowID
          ? neighborGroupIdx || levelIds[level - 1] || undefined
          : levelIds[level] || undefined
    });

    closeForm();
  }, [
    groupIds,
    level,
    calcID,
    title,
    rowType,
    rowID,
    selectedLevel,
    childrenIds,
    levelIds
  ]);

  const selectLevelHandler = (level: number) => {
    setSelectedLevel(level);
  };

  const titleChangeHandler = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setTitle(e.target.value);
  };

  const closeForm = () => {
    onToggleFormVisible('create');
  };

  return (
    <StyledCreateFormWrapper>
      {isLevelSelectShowed ? (
        <LevelSelect
          currentLevel={rowType === 'group' ? level - 1 : level}
          type={rowType}
          hideLevelsMore={true}
          selectedLevel={selectedLevel}
          onSetLevel={selectLevelHandler}
        />
      ) : (
        <GroupFolder color="primary" fontSize="large" />
      )}
      <Typography variant="subtitle2" pl={2}>
        Создание уровня
      </Typography>
      <StyledInput
        value={title}
        onChange={titleChangeHandler}
        placeholder="Введите название уровня"
      />
      {groupedTotalCount && (
        <GroupCount
          description="Будет сгруппировано"
          childrenCount={groupedTotalCount.childrenCount}
          groupCount={groupedTotalCount.groupCount}
        />
      )}
      <Stack ml={2} direction="row" spacing={2}>
        <Button
          color="success"
          size="small"
          disableElevation
          disabled={!title}
          onClick={createLevelClickHandler}>
          Сохранить
        </Button>
        <Button size="small" disableElevation onClick={closeForm}>
          Отменить
        </Button>
      </Stack>
    </StyledCreateFormWrapper>
  );
};
