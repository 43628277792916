/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import {
  ICalculationDictionary,
  ICalculationDictionaryResponseMutate,
  IDeleteRateWithRatePositions,
  useDeleteRatesWithPositionsMutation,
  useLazyGetCaclQuery
} from 'api/calculationDictionary';
import useConfirmDialog from 'hooks/useConfirmDialog';
import { enqueueSnackbar } from 'notistack';
import { IErrorData } from 'pages/Administration/AdminReferences/Prices/PricesReference.types';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addData, deleteData, getData, openDB } from 'utils/indexDB';
import { useGetActListQuery } from '../../../../api/calculations';
import { OrNull } from '../../../../api/references/estimates/estimates.types';
import { useCalcId } from '../../../../hooks/useCalcId';
import useSearchParam from '../../../../hooks/useSearchParams';
import { ActList } from '../../../../types';
import IntegrateDrawer from '../CalculationСomplicated/components/IntegrateDrawer/IntegrateDrawer';
import Tabs from './components/Tabs';
import UploadDrawer from './components/UploadDrawer';
import {
  HandbookContext,
  initialTabs,
  IRemovedIds,
  TTabType
} from './handbook.services';
import CalculationTab from './tabs/CalculationTab';
import { ICalculationDirectory } from './tabs/CalculationTab/CalculationTab.types';
import { ExecutedTab } from './tabs/ExecutedTab';
import VorTab from './tabs/VorTab';

export const HandbookCalculation: React.FC<ICalculationDirectory> = ({
  calculation,
  openEdit
}) => {
  const buttonContainer = React.useRef<HTMLDivElement>(null);
  const { data, refetch } = useGetActListQuery(
    { calcID: calculation?.id! },
    { skip: !calculation?.id }
  );

  const calcID = useCalcId();

  const [qs, setQs] = useSearchParam<TTabType>(initialTabs);

  // const [selectedTab, setSelectedTab] = React.useState<TTabType>(qs);

  const [openDrawer, setOpenDrawer] = React.useState(false);

  const changeTab = (_event: React.SyntheticEvent, tabName: TTabType) => {
    setQs(tabName);
  };

  const [integrateDrawer, setIntegrateDrawer] = useState(false);

  const toggleIntegrate = (flag: boolean) => {
    setIntegrateDrawer(flag);
  };
  const [currentAct, setCurrentAct] = React.useState<OrNull<ActList>>(null);

  React.useEffect(() => {
    if (calcID && data) {
      openDB().then(async (db) => {
        const result = await getData(db, calcID);
        if (result) {
          // const find = data.actList.find((_) => _?.id === result?.act?.actID);
          // if (find) {
          //   changeCurrentAct(find);
          // } else {
          //   changeCurrentAct(result.act);
          // }
          setCurrentAct(result.act);
        }
      });
    }
  }, [calcID, data]);

  const changeCurrentAct = React.useCallback(
    (act: ActList | null) => {
      openDB().then(async (db) => {
        if (act !== null) {
          addData(db, { id: calcID, act }).then(() => {
            setCurrentAct(act);
          });
        } else {
          deleteData(db, calcID).then(() => {
            setCurrentAct(null);
          });
        }
      });
    },
    [calcID]
  );

  const Content = React.useMemo(() => {
    switch (qs) {
      case 'calculation':
        return CalculationTab;
      case 'vor':
        return VorTab;
      case 'executed':
        return ExecutedTab;
      default:
        return CalculationTab;
    }
  }, [qs]);

  // React.useEffect(() => {
  //   if (selectedTab) {
  //     setSelectedTab(qs);
  //   }
  // }, [qs, selectedTab]);
  const [deleteRates] = useDeleteRatesWithPositionsMutation();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [parametersDialogOpen, setParametersDialogOpen] = useState(false);
  const [createDrawer, setCreateDrawer] = useState(false);
  const [removedIds, setRemovedIds] = useState<IRemovedIds>([]);
  const [selectedRow, setSelectedRow] = useState<null | ICalculationDictionary>(
    null
  );

  const findById = useCallback(
    (id: number, data?: ICalculationDictionaryResponseMutate) => {
      if (!data) return null;
      const find = data.rows.find((d) => d.id === id);
      if (!find) return null;
      if (find.rowType !== 'FOLDER') {
        findById(find.parentID!);
      } else {
        return find.id;
      }
    },
    []
  );

  const { t } = useTranslation('references');

  const [getCalc, { data: dataCacl, isFetching }] = useLazyGetCaclQuery();

  const removedState = useMemo(() => {
    const isRate = removedIds.some((el) => el.type === 'RATE');
    const countResources = removedIds.filter(
      (el) => el.type === 'RATE_POSITION' && el.id > 0
    ).length;
    return {
      title: isRate
        ? 'Подтвердите удаление расценки'
        : 'Подтвердите удаление ресурсов',
      body: isRate
        ? `Выбранная Вами расценка удалится вместе с ресурсами безвозвратно.
Количество ресурсов в расценке: ${countResources}.`
        : `Выбранные Вами ресурсы удалятся безвозвратно.\nКоличество: ${countResources}.`
    };
  }, [removedIds]);

  const {
    openConfirm: openDeleteRateConfirm,
    ConfirmDialog: DeleteRateConfirmDialog
  } = useConfirmDialog({
    isRedButton: true,
    handleConfirm: (confirm, fn) => {
      if (confirm) {
        fn?.();
      } else {
        setRemovedIds([]);
      }
    },
    ...removedState,

    abortHandler: () => {
      setRemovedIds([]);
    }
  });

  const deleteRatesWithPositions = useCallback(() => {
    const folderID = findById(removedIds?.[0]?.id, dataCacl);
    const body: IDeleteRateWithRatePositions = {
      ids: [],
      calcID,
      folderID: folderID || 0
    };
    openDeleteRateConfirm(() => {
      removedIds.forEach((candidate) => {
        switch (candidate.type) {
          case 'RATE':
            break;
          default:
            body.ids.push(candidate.id);
            break;
        }
      });

      deleteRates(body).then((resp) => {
        let refetch = true;
        if ('error' in resp) {
          refetch = false;
          const message = (resp as IErrorData<string>)?.error?.data;
          console.log(resp);
          if (message)
            enqueueSnackbar({
              variant: 'error',
              message: t(`estimates.${message}`),
              autoHideDuration: 5000
            });
        } else {
          enqueueSnackbar({ variant: 'success', message: 'Успешно удалено' });
        }
        setRemovedIds([]);
        if (selectedRow && body.ids.includes(selectedRow.id)) {
          setSelectedRow(null);
        }
      });
    });
  }, [
    findById,
    removedIds,
    dataCacl,
    calcID,
    openDeleteRateConfirm,
    deleteRates,
    selectedRow,
    t
  ]);

  return (
    <HandbookContext.Provider
      value={{
        buttonContainer: buttonContainer.current,
        openUploadDrawer: (f) => {
          setOpenDrawer((prevState) => f ?? !prevState);
        },
        toggleIntegrate,
        actList: data ?? null,
        currentAct,
        changeAct: changeCurrentAct,
        setAnchorEl,
        anchorEl,
        parametersDialogOpen,
        setParametersDialogOpen,
        createDrawer,
        setCreateDrawer,
        removedIds,
        setRemovedIds,
        selectedRow,
        setSelectedRow,
        deleteRatesWithPositions,
        openDeleteRateConfirm
      }}>
      <Tabs changeTab={changeTab} selectedTab={qs} calculation={calculation} />
      <Content calculation={calculation} openEdit={openEdit} />
      <UploadDrawer onClose={() => setOpenDrawer(false)} open={openDrawer} />
      <DeleteRateConfirmDialog />
      <IntegrateDrawer
        actList={data?.actList || []}
        changeAct={changeCurrentAct}
        act={currentAct}
        open={integrateDrawer}
        close={() => {
          toggleIntegrate(false);
        }}
        calculation={calculation}
      />
    </HandbookContext.Provider>
  );
};
