import { CircularProgress, Divider, MenuItem, Stack } from '@mui/material';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useSortTableMutation } from 'api/calculationDictionary';
import {
  useEditQuantityMutation,
  useGetHandbookExecutionListQuery
} from 'api/calculations';
import { useLazyGetKS2V2Query, useLazyGetKS6V2Query } from 'api/params';
import { KSResponse } from 'api/params/params.types';
import Progress from 'components/Progress';
import SwitchBtn from 'components/SwitchBtn';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import { useProjectId } from 'hooks/useProjectId';
import { enqueueSnackbar } from 'notistack';
import { WrapperAgGrid } from 'pages/Calculations/components/Accomplishment/Accomplishment.styles';
import ActDialog from 'pages/Calculations/components/Accomplishment/components/ActDialog';
import {
  SetupItem,
  SetupRow,
  SetupTable,
  StyledSelect
} from 'pages/Calculations/components/Accomplishment/components/CaptionTable/CaptionTable.style';
import CustomTableHeader from 'pages/Calculations/components/Accomplishment/components/CustomTableHeader';
import { StyledParametersButton } from 'pages/Calculations/components/CalculationBasic/components/CalculationLegend/CalculationLegend.styles';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useParams } from 'react-router-dom';
import {
  HandbookExecutionRowPartData,
  HandbookExecutionTreeData,
  TActStats
} from 'types';
import { addData, getData, openDB } from 'utils/indexDB';
import {
  ExecutedTabCellEditable,
  ExecutedTabCellRenderer,
  ExecutedTabContext,
  ExecutedTabHeaderGroupPart,
  ExecutedTabWrapper,
  formatDateExecutedTab,
  getHeadersPartsAdditional,
  getHeadersPartsDefault,
  getRowClassExecutedTab,
  getTotalExecutedTab,
  IExecutedTabContext,
  TExecutedTabPrice,
  TExecutedTabPriceValue
} from '.';
import { OrNull } from '../../../../../../api/references/estimates/estimates.types';
import { Badge } from '../../../Accomplishment/components/CaptionTable/CaptionTable';
import CalcMenu from '../../components/ProjectMenu';
import { HandbookContext } from '../../handbook.services';
import { Parameters } from '../CalculationTab/Parameters';
import { IVorTabProps } from '../VorTab/VorTab.types';
import { ExecutedTabPriceOptions } from './ExecutedTab.constants';
import isEqual from 'lodash/isEqual';

interface Stats {
  title?: string;
  color?: string;
}

const isDeepEqual = (a: any, b: any) => {
  return isEqual(a, b);
};

const statusesAct: Record<TActStats, OrNull<Stats>> = {
  NEW: null,
  PREPARED: {
    title: 'Период изменен',
    color: '#FF9800'
  },
  CLOSED: {
    title: 'Период закрыт',
    color: '#2E7D32'
  },
  COLLECTING: {
    title: 'Получение факта',
    color: '#A14DE7'
  },
  REOPENED: {
    title: 'Период изменен',
    color: '#FF9800'
  }
};

export const ExecutedTab: React.FC<IVorTabProps> = ({
  calculation,
  openEdit
}) => {
  // ******* Search Params *******
  const { calcID: searchCalcID } = useParams();
  const calcID = searchCalcID ? +searchCalcID : 0;

  // ******* HandbookExecutionList Data *******
  const {
    data: dataHandbookExecutionList,
    isFetching: isFetchingHandbookExecutionList,
    refetch
  } = useGetHandbookExecutionListQuery({ calcID });

  const {
    actList,
    changeAct: changeCurrentAct,
    currentAct,
    toggleIntegrate
  } = useContext(HandbookContext);

  const [collapseRowsIds, setCollapseRowsIds] = useState<number[]>([]);

  const total: HandbookExecutionTreeData[] = useMemo(
    () => [getTotalExecutedTab(dataHandbookExecutionList?.total ?? [])],
    [dataHandbookExecutionList?.total]
  );

  const defaultData: HandbookExecutionTreeData[] = useMemo(
    () => dataHandbookExecutionList?.tree ?? [],
    [dataHandbookExecutionList?.tree]
  );

  const checkParent = useCallback(
    (item: HandbookExecutionTreeData, collapseRowsIds: number[]): boolean => {
      return item.parentID ? collapseRowsIds.includes(item.parentID) : true;
    },
    []
  );

  interface ITotalActs {
    endDate: string;
    startDate: string;
  }
  const data: HandbookExecutionTreeData[] = useMemo(
    () => defaultData.filter((e) => checkParent(e, collapseRowsIds)),
    [defaultData, checkParent, collapseRowsIds]
  );

  const totalParts: HandbookExecutionRowPartData[] = useMemo(() => {
    return total[0].parts ?? [];
  }, [total]);

  const prevTotalActsRef = useRef<ITotalActs[] | undefined>(undefined);

  const totalActs = useMemo(() => {
    const currentParts: ITotalActs[] =
      (total?.[0]?.parts ?? []).map((e) => ({
        endDate: e.endDate ?? '',
        startDate: e.startDate ?? ''
      })) ?? [];

    if (!isEqual(prevTotalActsRef.current, currentParts)) {
      prevTotalActsRef.current = currentParts;

      return currentParts;
    }
    return prevTotalActsRef.current;
  }, [total]);

  const dataFirstFilterTreeActs = useMemo(
    () => (actList?.actList ? actList.actList : []),
    [actList]
  );
  const dataFirstFilterTreeLength = useMemo(
    () => dataFirstFilterTreeActs.length,
    [dataFirstFilterTreeActs]
  );

  // ******* ActDialog *******
  const [createModal, setCreateModal] = useState(false);

  // ******* Export *******
  const [getKS2V2, { isFetching: isFetchingKS2V2 }] = useLazyGetKS2V2Query();
  const [getKS6V2, { isFetching: isFetchingKS6V2 }] = useLazyGetKS6V2Query();

  // ******* Table ref *******
  const RefTable = useRef<AgGridReact<HandbookExecutionTreeData> | null>(null);
  const [refColumns, setRefColumns] = useState<
    | (
        | ColDef<HandbookExecutionTreeData>
        | ColGroupDef<HandbookExecutionTreeData>
      )[]
    | undefined
  >();

  // ******* Switch Prices *******
  const [prices, setPrices] = useState<TExecutedTabPriceValue>('curr');

  const disableSwitchPrices = useMemo(
    () => !dataHandbookExecutionList?.tree.length,
    [dataHandbookExecutionList?.tree]
  );
  const changePrices = (price: TExecutedTabPrice) => {
    setPrices(price.value);
  };

  // ******* Table Data *******

  const renderAct = useMemo(() => {
    if (!currentAct) return 'Выберите диапазон';
    return formatDateExecutedTab(currentAct);
  }, [currentAct]);

  useEffect(() => {
    if (data && calcID && !!dataFirstFilterTreeActs.length) {
      openDB().then(async (db) => {
        const result = await getData(db, calcID);
        if (result) {
          const find = dataFirstFilterTreeActs.find(
            (_) => _?.id === result?.act?.id
          );
          if (find) {
            changeCurrentAct(find);
          } else {
            changeCurrentAct(result.act);
          }
        } else {
          const lastAct =
            dataFirstFilterTreeActs[dataFirstFilterTreeActs.length - 1];
          addData(db, { id: calcID, act: lastAct }).then(() => {
            changeCurrentAct(lastAct);
          });
        }
      });
    }
  }, [data, calcID, dataFirstFilterTreeActs, changeCurrentAct]);

  // ******* Table Void *******
  const getNestedChildrenIds = useCallback(
    (id: number, defaultData: HandbookExecutionTreeData[]): number[] => {
      const children = defaultData.filter((e) => e.parentID === id);

      return children.reduce<number[]>(
        (acc, child) =>
          [
            ...acc,
            child.id,
            ...getNestedChildrenIds(child.id, defaultData)
          ].filter((e) => e !== undefined),
        []
      );
    },
    []
  );

  const onCollapse = useCallback(
    (id: number) => {
      setCollapseRowsIds((prevState) => {
        if (prevState.includes(id)) {
          const idsToRemove = getNestedChildrenIds(id, defaultData);
          return prevState.filter(
            (hrId) => ![id, ...idsToRemove].includes(hrId)
          );
        } else {
          return [...prevState, id];
        }
      });
    },
    [defaultData, getNestedChildrenIds]
  );

  // ******* onUpdate *******
  const [update] = useEditQuantityMutation();

  const onUpdate: IExecutedTabContext['onUpdate'] = useCallback(
    async (value: string, key: 'quantity', actID: number, rowID: number) => {
      RefTable.current?.api?.showLoadingOverlay();
      try {
        const editedData = {
          actID: actID,
          calcID: calcID,
          body: {
            rowID: rowID,
            [key]:
              value === undefined || value === null || value === '0'
                ? null
                : Number(value)
          }
        };
        await update(editedData).then(() => refetch?.());
      } catch (error) {
        console.error('Error:', error);
      }
      RefTable.current?.api?.hideOverlay();
    },
    [calcID, refetch, update]
  );

  // ******* Table Context *******
  const contextTable = useMemo<IExecutedTabContext>(
    () => ({
      calcID,
      total,
      collapseRowsIds,
      onCollapse,
      onUpdate,
      current: currentAct,
      data,
      prices
    }),
    [
      calcID,
      collapseRowsIds,
      currentAct,
      data,
      onCollapse,
      onUpdate,
      prices,
      total
    ]
  );

  useEffect(() => {
    if (isFetchingHandbookExecutionList) {
      RefTable.current?.api?.showLoadingOverlay();
    } else {
      RefTable.current?.api?.hideOverlay();
    }
  }, [isFetchingHandbookExecutionList, contextTable]);

  useEffect(() => {
    RefTable.current?.api?.refreshCells({ force: true, suppressFlash: true });
    RefTable.current?.api?.refreshHeader();
  }, [data, currentAct]);

  useEffect(() => {
    if (totalParts.length) {
      setRefColumns([
        ...getHeadersPartsDefault(),
        ...getHeadersPartsAdditional(totalParts)
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalParts.length, totalActs]);

  useEffect(() => {
    RefTable.current?.api?.refreshCells({ force: true, suppressFlash: true });
    RefTable.current?.api?.refreshHeader();
  }, [totalParts]);

  useEffect(() => {
    if (defaultData) {
      const uniqueParentIds = Array.from(
        new Set(
          (defaultData ?? [])
            .filter((e) => {
              const parent = defaultData.find((a) => a.id === e.parentID);
              if (parent?.lvl !== null) return true;
              return false;
            })
            .map((e) => e.parentID)
            .filter((id): id is number => id !== null)
        )
      );

      setCollapseRowsIds((prevState) =>
        prevState.length ? prevState : uniqueParentIds
      );
    }
  }, [defaultData]);

  // ******* Menu *******
  const {
    buttonContainer,
    anchorEl,
    setAnchorEl,
    parametersDialogOpen,
    setParametersDialogOpen
  } = useContext(HandbookContext);

  // ******* Download *******
  const download = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const key = e.currentTarget.dataset?.key;
      const getKS = key === 'ks-2' ? getKS2V2 : getKS6V2;
      if (calcID && currentAct && key && currentAct.id) {
        enqueueSnackbar(
          'Ожидайте загрузку архива, около 2 минут. Не покидайте вкладку.',
          {
            autoHideDuration: 10000,
            variant: 'info'
          }
        );
        getKS({ calcID: Number(calcID), actID: currentAct.id })
          .then((response) => {
            const res = { data: response.data! as KSResponse };
            enqueueSnackbar('Загрузка завершена', {
              autoHideDuration: 2000,
              variant: 'success'
            });
            const sliceSize = 1024;
            const byteCharacters = atob(res?.data?.archiveBase64 as string);
            const byteArrays = [];

            for (
              let offset = 0;
              offset < byteCharacters.length;
              offset += sliceSize
            ) {
              const slice = byteCharacters.slice(offset, offset + sliceSize);
              const byteNumbers = new Array(slice.length);
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }
              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }
            const blob = new Blob(byteArrays, { type: 'application/zip' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${key === 'ks-2' ? 'КС-2' : 'КС-6A'}.zip`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch((er) => {
            console.log(er);
            enqueueSnackbar(
              'При загрузке архива произошла ошибка, попробуйте позже',
              {
                autoHideDuration: 2000,
                variant: 'error'
              }
            );
          });
      }
    },
    [calcID, currentAct, getKS2V2, getKS6V2]
  );
  // ******* Отсортировать ресурсы *******
  const [sortTable] = useSortTableMutation();
  const handleRefreshTable = useCallback(() => {
    if (calcID) {
      sortTable(calcID);
    }
  }, [calcID, sortTable]);

  // ******* Breadcrumbs *******
  const projectId = useProjectId();
  useBreadcrumbs(
    [
      { title: `Расчеты`, url: `/projects/${projectId}/calculations` },
      { title: `${calculation?.title}` }
    ],
    [calculation, projectId]
  );
  // ******* Render *******
  return (
    <>
      <ExecutedTabWrapper>
        <SetupTable style={{ borderBottom: '1px solid rgb(210, 220, 255)' }}>
          <SetupRow>
            <SetupItem hideBefore>
              Акт:
              {!dataFirstFilterTreeLength ? (
                <StyledParametersButton
                  onClick={() => setCreateModal(true)}
                  fullWidth
                  sx={{ fontSize: '14px' }}
                  variant={'outlined'}>
                  Создать новый акт
                </StyledParametersButton>
              ) : (
                <StyledSelect
                  width={235}
                  onClear={() => {}}
                  nullableValues={[renderAct, 'Выберите диапазон']}
                  SelectProps={{
                    renderValue: () => renderAct,
                    MenuProps: {
                      sx: {
                        maxHeight: 500,
                        width: 235
                      },
                      placeholder: 'Выберите диапазон'
                    },
                    inputProps: {
                      sx: {
                        padding: '4.5px 14px'
                      }
                    },
                    value: renderAct || 'Выберите диапазон',
                    placeholder: 'Выберите диапазон'
                  }}
                  value={renderAct || 'Выберите диапазон'}
                  placeholder={'Выберите диапазон'}
                  fullWidth
                  select>
                  <Stack direction="row" px={1} py={1}>
                    <StyledParametersButton
                      onClick={() => setCreateModal(true)}
                      fullWidth
                      sx={{ fontSize: '14px' }}
                      variant={'outlined'}>
                      Создать новый акт
                    </StyledParametersButton>
                  </Stack>
                  <Divider />
                  <MenuItem
                    style={{ height: 0, overflow: 'hidden', padding: 0 }}
                    selected
                    hidden={true}
                    value={'Выберите диапазон'}>
                    Выберите диапазон
                  </MenuItem>
                  {dataFirstFilterTreeActs?.map((item) => {
                    return (
                      <MenuItem
                        key={item.id}
                        onClick={() => {
                          if (currentAct?.id !== item.id) {
                            changeCurrentAct(item);
                          }
                        }}
                        selected={
                          formatDateExecutedTab(item) === renderAct &&
                          item.id === currentAct?.id
                        }
                        value={formatDateExecutedTab(item)}>
                        {formatDateExecutedTab(item)}
                      </MenuItem>
                    );
                  })}
                </StyledSelect>
              )}
            </SetupItem>
            <SetupItem>
              Экспорт:
              <StyledParametersButton
                data-key={'ks-2'}
                disabled={isFetchingKS2V2 || !currentAct}
                fullWidth
                sx={{ fontSize: '16px', fontWeight: '400', height: '32px' }}
                onClick={download}
                variant={'outlined'}>
                {isFetchingKS2V2 ? <CircularProgress size={20} /> : 'КС-2'}
              </StyledParametersButton>
              <StyledParametersButton
                data-key={'ks-6'}
                onClick={download}
                disabled={isFetchingKS6V2 || !currentAct}
                fullWidth
                sx={{ fontSize: '16px', fontWeight: '400', height: '32px' }}
                variant={'outlined'}>
                {isFetchingKS6V2 ? <CircularProgress size={20} /> : 'КС-6а'}
              </StyledParametersButton>
            </SetupItem>
            <SetupItem>
              Цены:
              <SwitchBtn
                disableCurr={disableSwitchPrices}
                value={prices}
                onClick={changePrices}
                options={ExecutedTabPriceOptions}
              />
            </SetupItem>
          </SetupRow>
          <SetupRow>
            {currentAct && currentAct.status && (
              <Badge {...statusesAct[currentAct.status || 'NEW']} />
            )}
          </SetupRow>
        </SetupTable>
        <WrapperAgGrid className="ag-theme-material reference-prices">
          <ExecutedTabContext.Provider value={contextTable}>
            <AgGridReact
              context={contextTable}
              ref={RefTable}
              defaultColDef={{ suppressMovable: true, resizable: true }}
              enableCellTextSelection={true}
              ensureDomOrder={true}
              maintainColumnOrder={true}
              groupHeaderHeight={25}
              singleClickEdit
              gridOptions={{
                navigateToNextHeader: () => null,
                tabToNextHeader: () => null,
                components: {
                  cellRenderer: ExecutedTabCellRenderer,
                  cellEditable: ExecutedTabCellEditable,
                  headerGroupCustom: CustomTableHeader,
                  headerGroupPart: ExecutedTabHeaderGroupPart
                }
              }}
              columnDefs={refColumns?.length ? refColumns : undefined}
              rowData={refColumns?.length ? data : undefined}
              pinnedTopRowData={refColumns?.length ? total : undefined}
              suppressCellFocus={true}
              onFirstDataRendered={(event) => {
                event.api.sizeColumnsToFit();
              }}
              onGridSizeChanged={(event) => {
                event.api.sizeColumnsToFit();
              }}
              onViewportChanged={(event) => {
                event.api.sizeColumnsToFit();
              }}
              getRowId={(params) => {
                return (params.data.id || '').toString();
              }}
              getRowClass={getRowClassExecutedTab}
              getRowHeight={(params) => {
                if (params.node.rowPinned === 'top') {
                  return 40;
                }
                return 80;
              }}
              rowStyle={{
                padding: '0 !important'
              }}
              rowHeight={80}
              headerHeight={40}
              loadingOverlayComponent={Progress}
              noRowsOverlayComponent={Progress}
            />
          </ExecutedTabContext.Provider>
        </WrapperAgGrid>
        <ActDialog
          data={actList ?? undefined}
          update={() => {
            RefTable?.current?.api?.showLoadingOverlay();
          }}
          open={createModal}
          close={() => setCreateModal(false)}
        />
      </ExecutedTabWrapper>
      <CalcMenu
        isCalc={false}
        openEdit={openEdit}
        anchor={anchorEl}
        setAnchor={setAnchorEl}
        calculation={calculation}
      />
      <Parameters
        open={parametersDialogOpen}
        calculation={calculation}
        close={() => setParametersDialogOpen(false)}
        isActs={true}
      />
    </>
  );
};
