/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ICellRendererParams } from 'ag-grid-community';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { ITangleTableResponse } from '../../../../../../api/tangl/tangl.types';
import { ReactComponent as RowIcon } from '../../../../../../assets/icons/LeveLicon/RowIcon.svg';
import Cell from '../../../Accomplishment/components/Cell';
import { TangleTableContext } from '../../TanglTable';

interface ILevelTangl
  extends ICellRendererParams<ITangleTableResponse, any, any> {
  collapse?: any;
  hiddenArr?: any[];
}

const Wrapper = styled.div<{
  floatRight?: boolean;
  arrowClose: boolean;
  cursor: boolean;
}>`
  cursor: ${({ cursor }) => (cursor ? 'pointer' : 'default')};
  height: 100%;
  display: grid;
  grid-template-columns: 30px 1fr;
  justify-items: center;
  align-items: center;
  width: 100%;
  svg {
    margin: 7px;
    rotate: 0deg;
    //transition: rotate linear 0.2s;
    ${({ floatRight }) => floatRight && '&:last-child {margin-right: 0;}'}
    ${({ arrowClose }) => arrowClose && '&:first-child {rotate: 180deg;}'}
  }
`;
const BadgeLevel = styled.div`
  border-radius: 4px;
  height: 20px;
  background: #e5ecf7;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #0044b4;
  color: #0044b4;
  text-transform: uppercase;
  padding: 2px 4px;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-family: 'Roboto', sans-serif;
`;
const Level: React.FC<ILevelTangl> = ({ data, node }) => {
  const { collapse, hiddenRowsIds } = useContext(TangleTableContext);
  const close = useMemo(() => {
    return hiddenRowsIds?.includes(data?.id!) || false;
  }, [hiddenRowsIds]);

  const onClick = () => {
    collapse?.(data?.id!);
  };

  switch (data?.type) {
    case 'work':
    case 'folder': {
      const isCursour = data.children !== null && !!data?.children?.length;
      return (
        <Wrapper
          cursor={isCursour}
          onClick={() => isCursour && onClick()}
          arrowClose={close}>
          {data?.children?.length ? <KeyboardArrowUpIcon /> : <span />}
          {data.type !== 'folder' &&
          (data.children === null ||
            (data?.children?.length &&
              (data.children[0]?.type === 'resource' ||
                data?.children?.length === 0))) ? (
            <RowIcon />
          ) : (
            <BadgeLevel>ур {data.lvl}</BadgeLevel>
          )}
        </Wrapper>
      );
    }
    // case 'resource':
    //   return (
    //     <Wrapper onClick={() => {}} arrowClose={false}>
    //       {/*{data?.children?.length ? <KeyboardArrowUpIcon /> : <span />}*/}
    //       <span />
    //       <RowIcon />
    //     </Wrapper>
    //   );
    // return (
    //   <Wrapper onClick={onClick} arrowClose={close}>
    //     {data?.children?.length ? <KeyboardArrowUpIcon /> : <span />}
    //     <RowIcon />
    //   </Wrapper>
    // );
    case 'resource':
      return null;
    default:
      return (
        <Cell
          color={data?.lvl === 1 || data?.lvl === 0 ? '#0044B4' : undefined}
          weight={data?.lvl === 1 || data?.lvl === 0}
          float={'center'}>
          Итого
        </Cell>
      );
  }
};

export default Level;
