/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 * @author CyrilStrone<77802640+CyrilStrone@users.noreply.github.com>
 */
import { Box, Dialog } from '@mui/material';
import {
  useDeleteActMutation,
  useSyncCoefficientsActMutation,
  useUpdateActMutation
} from 'api/params';
import {
  useGetActsCoefficientsCalculationQuery,
  useGetCoefficientsCalculationQuery,
  useUpdateActCoefficientsCalculationMutation,
  useUpdateCoefficientsCalculationMutation
} from 'api/references/estimates';
import { IUpdateData } from 'api/references/estimates/estimates.types';
import { Formik } from 'formik';
import { useCalcId } from 'hooks/useCalcId';
import { enqueueSnackbar } from 'notistack';
import React, {
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { CalcData } from 'types';
import { toLocalString } from 'utils/formatDate';
import {
  Form,
  getActListFromLabel,
  getDifferentActList,
  getDifferentCoefficient,
  IParametersForm,
  IParametersFormActList,
  parametersPopperSetup,
  processCoefficients
} from '.';
import { HandbookContext, TTabType } from '../../../handbook.services';
import { checkDates } from '../helper';

export const Parameters: React.FC<{
  open: boolean;
  close: () => void;
  isActs: boolean;
  calculation?: CalcData;
}> = ({ open, close, isActs, calculation }) => {
  const [searchParams] = useSearchParams();

  const [selectedTab] = useState<TTabType | null>(
    searchParams.get('tab') as TTabType | null
  );

  const calcID = useCalcId();

  const { actList, currentAct } = useContext(HandbookContext);

  const [updateCalculation] = useUpdateCoefficientsCalculationMutation();

  const [updateActCalculation] = useUpdateActCoefficientsCalculationMutation();

  const [updateAct] = useUpdateActMutation();

  const [deleteAct] = useDeleteActMutation();

  const [syncAct] = useSyncCoefficientsActMutation();

  const { data: coffData } = useGetCoefficientsCalculationQuery(calcID, {
    skip: isActs
  });

  const { data: coffActData } = useGetActsCoefficientsCalculationQuery(
    {
      calcID
    },
    { skip: !isActs }
  );

  const initialValues = useMemo((): IParametersForm => {
    const newActList: IParametersFormActList['actList'] =
      coffActData?.coefficients
        ? (actList?.actList ?? []).map((e) => {
            const actCoefficient = coffActData?.coefficients.find(
              (a) => a.actID === e.id
            );
            const year = e.onDate
              ? new Date(e.onDate).getFullYear().toString()
              : 'unknown';

            return {
              id: e.id,
              onDate: new Date(e.onDate ?? ''),
              startDate: new Date(e.startDate ?? ''),
              endDate: new Date(e.endDate ?? ''),
              status: e.status,
              syncedAt: e.syncedAt,
              responsible: e.responsible,
              isSynchronized: actCoefficient?.synchronized ?? false,
              isRemoved: false,
              year: year,
              coefficient: {
                index: actCoefficient?.coefficients.index,
                kz: actCoefficient?.coefficients.kz
              }
            };
          })
        : [];
    const actListFromLabel = getActListFromLabel(newActList);
    const differentActList = getDifferentActList(actListFromLabel);
    const coefficient = coffData ? getDifferentCoefficient(coffData) : null;

    return {
      actListFromLabel: differentActList,
      actListFromLabelLength: newActList.length,
      coefficient: {
        index: coefficient?.index,
        kz: coefficient?.kz
      }
    };
  }, [actList?.actList, coffActData?.coefficients, coffData]);

  const onSubmit = useCallback(
    (values: IParametersForm) => {
      const errors: {
        [key: string]: {
          er: {
            start: null | ReactElement;
            end: null | ReactElement;
            on: null | ReactElement;
            split: null | ReactElement;
          };
        };
      } = {};
      values.actListFromLabel?.forEach((labels) => {
        labels.actList.forEach((act, idx) => {
          const er = checkDates(
            act,
            values.actListFromLabel.map((e) => ({
              label: e.label,
              fields: e.actList
            }))
          );
          const key = `За ${new Date(act.onDate || '')?.getFullYear()} год, строка ${idx + 1}`;
          if (er?.end || er?.on || er?.split || er?.start) {
            if (errors[key]) {
              errors[key] = { ...errors[key], er };
            } else {
              errors[key] = { er };
            }
          }
        });
      });

      const keysErrors = Object.keys(errors);
      if (isActs && keysErrors.length) {
        enqueueSnackbar(
          <Box display={'flex'} flexDirection={'column'} gap={0.05}>
            Ошибка в Актах по периодам:
            {keysErrors.map((label) => {
              const err = errors[label].er;
              return (
                <Box key={label} display={'flex'} flexDirection={'column'}>
                  <p>{label}</p>
                  <Box display={'flex'} gap={0.2} flexDirection={'column'}>
                    {err?.start}
                    {err?.end}
                    {err?.on}
                    {err?.split}
                  </Box>
                </Box>
              );
            })}
          </Box>,
          {
            variant: 'error',
            autoHideDuration: 3000
          }
        );
        return;
      }

      if (isActs) {
        const actList = values.actListFromLabel.flatMap((e) => e.actList);
        actList.forEach((e) => {
          if (e.isRemoved) {
            deleteAct({ calcID, actID: e.id });
          } else if (e.isSynchronized) {
            syncAct({
              calcID,
              actID: e.id,
              projectType: 'handbk'
            });
          }
        });
        updateAct({
          calcID,
          values: actList
            .filter((act) => !act.isRemoved || !act.isSynchronized)
            .map((act) => {
              return {
                id: act.id,
                endDate: toLocalString(act.endDate as Date),
                startDate: toLocalString(act.startDate as Date)
              };
            })
        });
        actList
          .filter((act) => !act.isRemoved || !act.isSynchronized)
          .forEach((act) => {
            const kz: IUpdateData[] = processCoefficients(
              act.coefficient.kz?.coefficients ?? [],
              'KZ'
            );
            const index: IUpdateData[] = processCoefficients(
              act.coefficient.index?.coefficients ?? [],
              'INDEX'
            );

            const indexGlobalCoeff = act.coefficient.index?.global;
            const indexGlobal: IUpdateData[] = indexGlobalCoeff
              ? processCoefficients([indexGlobalCoeff], 'INDEX')
              : [];

            const kzGlobalCoeff = act.coefficient.kz?.global;
            const kzGlobal: IUpdateData[] = kzGlobalCoeff
              ? processCoefficients([kzGlobalCoeff], 'KZ')
              : [];

            updateActCalculation({
              calcID,
              actID: act.id,
              coefficients: [...index, ...kz, ...indexGlobal, ...kzGlobal]
            });
          });
        close();
      } else {
        const kz: IUpdateData[] = processCoefficients(
          values.coefficient.kz?.coefficients ?? [],
          'KZ'
        );
        const index: IUpdateData[] = processCoefficients(
          values.coefficient.index?.coefficients ?? [],
          'INDEX'
        );

        const indexGlobalCoeff = values.coefficient.index?.global;
        const indexGlobal: IUpdateData[] = indexGlobalCoeff
          ? processCoefficients([indexGlobalCoeff], 'INDEX')
          : [];

        const kzGlobalCoeff = values.coefficient.kz?.global;
        const kzGlobal: IUpdateData[] = kzGlobalCoeff
          ? processCoefficients([kzGlobalCoeff], 'KZ')
          : [];
        updateCalculation({
          calcID,
          coefficients: [...index, ...kz, ...indexGlobal, ...kzGlobal]
        }).then(() => close());
      }
    },
    [
      calcID,
      close,
      deleteAct,
      isActs,
      syncAct,
      updateAct,
      updateActCalculation,
      updateCalculation
    ]
  );

  return (
    <Dialog PaperProps={parametersPopperSetup} open={open}>
      <Formik<IParametersForm>
        enableReinitialize={true}
        validateOnChange
        initialValues={initialValues}
        onSubmit={onSubmit}>
        {({ values }) => {
          return (
            <Form
              selectedTab={selectedTab}
              values={values}
              calculation={calculation}
              closeForm={close}
              isActs={isActs}
              calcID={calcID}
              currentAct={currentAct}
              open={open}
            />
          );
        }}
      </Formik>
    </Dialog>
  );
};
