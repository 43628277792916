import { Box } from '@mui/material';
import { FC, memo, useEffect, useMemo } from 'react';
import { StyledTreeNodeWrapper } from './TreeNodeRow.styles';
import { TreeNodeRowProps } from './TreeNodeRow.types';
import { CreateLevelForm, MoveGroupForm, Row } from './components';
import {
  TreeNodeContextConsumer,
  TreeNodeContextProvider,
  useTreeNodeContext
} from './context';
import { VORGroupData } from '../../../../../api/calculations/types';

export const TreeNodeRow: FC<
  TreeNodeRowProps & {
    isHandbook: boolean;
    hasParent: boolean;
    data?: VORGroupData[];
    initialIndex?: number;
  }
> = memo(
  ({
    rows,
    level,
    levelIds,
    childrenIds: childrenIdsFromProps,
    groupIds: groupIdsFromProps,
    checkboxShowed,
    maxDeep,
    isHandbook,
    hasParent,
    data,
    ...rest
  }): JSX.Element => {
    const parentLevel = useMemo(() => level, []);

    return (
      <Box>
        {checkboxShowed && <MoveGroupForm isHandbook={isHandbook} />}
        {rows.map((row, index) => {
          let deep: number | undefined = undefined;
          if (level === 0 && Array.isArray(maxDeep)) {
            deep = maxDeep[index];
          }

          return (
            <TreeNodeContextProvider
              maxDeep={deep ?? maxDeep}
              key={row.id}
              rowID={row.id}
              rowTitle={row.title}
              rowType={row.type}
              levelIds={levelIds}
              hasParent={hasParent}
              level={row.level ?? level}
              data={data}
              rowChildren={row.children}>
              <TreeNodeContextConsumer>
                {({
                  selectedIds,
                  levelIds,
                  groupIds,
                  level,
                  childrenIds,
                  formVisible,
                  isExpanded,
                  onSetSelectedIds
                }) => (
                  <>
                    {formVisible.create && (
                      <CreateLevelForm
                        index={index}
                        isHandbook={true}
                        groupIds={groupIdsFromProps}
                        childrenIds={childrenIdsFromProps}
                      />
                    )}
                    <Row
                      isHandbook={isHandbook}
                      index={index}
                      row={row}
                      parentLevel={parentLevel}
                      parentGroupIds={groupIdsFromProps}
                      checkboxShowed={checkboxShowed}
                      parentChildrenLength={childrenIdsFromProps.length}
                      {...rest}
                    />
                    <StyledTreeNodeWrapper $isExpanded={isExpanded}>
                      <TreeNodeRow
                        isHandbook={isHandbook}
                        maxDeep={deep ?? maxDeep}
                        level={level}
                        rows={row.children}
                        levelIds={levelIds}
                        selectedIds={selectedIds}
                        childrenIds={childrenIds}
                        hasParent={true}
                        groupIds={groupIds}
                        checkboxShowed={formVisible.move}
                        data={data}
                        onSetSelectedIds={onSetSelectedIds}
                      />
                    </StyledTreeNodeWrapper>
                  </>
                )}
              </TreeNodeContextConsumer>
            </TreeNodeContextProvider>
          );
        })}
      </Box>
    );
  }
);

TreeNodeRow.displayName = 'TreeNodeRow';
