/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 * @author CyrilStrone<77802640+CyrilStrone@users.noreply.github.com>
 */

import ClearIcon from '@mui/icons-material/Clear';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box } from '@mui/material';
import {
  ICoefficientData,
  OrNull
} from 'api/references/estimates/estimates.types';
import { ToggleButton } from 'components/ToggleButton';
import { ToggleButtonGroup } from 'components/ToggleButtonGroup';
import { Field, FieldProps, useFormikContext } from 'formik';
import { PopperComponent } from 'pages/Administration/AdminReferences/Prices/useTableData';
import Cell from 'pages/Calculations/components/Accomplishment/components/Cell';
import { StyledClearableTextField } from 'pages/Calculations/components/CalculationBasic/components/ParametersDialog/components/TabComponent/TabComponent.styles';
import React, { useMemo, useRef, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import {
  Badge,
  getValueParameters,
  InformIcon,
  IParametersForm,
  IParametersUpdateValue,
  ListRow,
  setValueParameters
} from '../../';
import { clearData, validate } from '../../../helper';

export const Row = ({
  row,
  itemKey,
  updateValue,
  actId
}: {
  row: ICoefficientData;
  itemKey: string;
  updateValue: <T extends keyof ICoefficientData>(
    props: IParametersUpdateValue<T>
  ) => void;
  actId?: number;
}) => {
  const [show, setShow] = useState(false);
  const { setFieldValue, getFieldProps } = useFormikContext<IParametersForm>();

  const refPz = useRef<OrNull<HTMLElement>>(null);
  const refFot = useRef<OrNull<HTMLElement>>(null);
  const refMim = useRef<OrNull<HTMLElement>>(null);
  const refMtr = useRef<OrNull<HTMLElement>>(null);

  const isPercent = !!row.isPercent;
  const isDisabledPZ = !!row?.fot || !!row?.mim || !!row?.mtr;
  const isDisabledRest = !!row?.pz;

  const suffix = useMemo(() => {
    return isPercent ? '%' : undefined;
  }, [isPercent]);

  const HelpIcon = useMemo(() => {
    return row?.isDifferent ? <InformIcon /> : null;
  }, [row]);

  return (
    <React.Fragment>
      <Box
        paddingLeft={`${row.lvl! > 1 ? 12 * (row.lvl! - 1) : 0}px`}
        gap={'4px'}
        display={'flex'}
        alignItems={'center'}>
        <Box display={'flex'} alignItems={'center'} gap={'4px'}>
          {!!row.children?.length && row.rowType === 'FOLDER' ? (
            <KeyboardArrowDownIcon
              style={{ cursor: 'pointer', rotate: show ? '180deg' : '0deg' }}
              onClick={() => setShow((prevState) => !prevState)}
            />
          ) : (
            <div style={{ width: '24px' }} />
          )}
          {row.rowType! === 'FOLDER' ? (
            <Badge>УР {row.lvl}</Badge>
          ) : (
            <div>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15.5556 4H5.77778C4.8 4 4 4.8 4 5.77778V18.2222C4 19.2 4.8 20 5.77778 20H18.2222C19.2 20 20 19.2 20 18.2222V8.44444L15.5556 4ZM7.55556 7.55556H12V9.33333H7.55556V7.55556ZM16.4444 16.4444H7.55556V14.6667H16.4444V16.4444ZM16.4444 12.8889H7.55556V11.1111H16.4444V12.8889ZM14.6667 9.33333V5.77778L18.2222 9.33333H14.6667Z"
                  fill="#7890B2"
                />
              </svg>
            </div>
          )}
        </Box>
        <Cell>{row.title}</Cell>
      </Box>
      <Field name={`${itemKey}.isPercent`}>
        {({ field }: FieldProps<boolean, IParametersForm>) => (
          <ToggleButtonGroup
            {...field}
            exclusive
            fullWidth
            onChange={() => {
              updateValue({
                actID: actId,
                rowID: row.rowID,
                isGlobal: false,
                key: 'isPercent',
                value: !field.value
              });
            }}>
            {['К', '%'].map((groupButton) => {
              const selected = groupButton === 'К' ? !field.value : field.value;
              return (
                <ToggleButton
                  key={groupButton}
                  value={groupButton}
                  selected={selected}>
                  {groupButton}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        )}
      </Field>
      <Field name={`${itemKey}.pz`} validateOnChange={true} validate={validate}>
        {({
          field,
          meta
        }: FieldProps<OrNull<number> | string, IParametersForm>) => (
          <>
            <NumericFormat
              {...field}
              value={getValueParameters(field.value, isPercent)}
              onChange={(e) => {
                updateValue({
                  actID: actId,
                  isGlobal: false,
                  rowID: row.rowID,
                  key: 'pz',
                  value: setValueParameters(e.target.value, isPercent)
                });
              }}
              InputProps={{
                disabled: isDisabledPZ,
                endAdornment: null,
                ref: refPz
              }}
              customInput={StyledClearableTextField}
              placeholder={isPercent ? '%' : 'К'}
              allowNegative={false}
              thousandSeparator={' '}
              decimalSeparator=","
              decimalScale={isPercent ? 13 : 15}
              suffix={suffix}
              onClear={() => setFieldValue(field.name, '')}
            />
            <PopperComponent message={meta.error} refItem={refPz.current} />
          </>
        )}
      </Field>
      <Field
        name={`${itemKey}.fot`}
        validateOnChange={true}
        validate={validate}>
        {({
          field,
          meta
        }: FieldProps<OrNull<number> | string, IParametersForm>) => (
          <>
            <NumericFormat
              {...field}
              value={getValueParameters(field.value, isPercent)}
              onChange={(e) => {
                updateValue({
                  actID: actId,
                  isGlobal: false,
                  rowID: row.rowID,
                  key: 'fot',
                  value: setValueParameters(e.target.value, isPercent)
                });
              }}
              InputProps={{
                disabled: isDisabledRest,
                endAdornment: null,
                ref: refFot
              }}
              customInput={StyledClearableTextField}
              allowNegative={false}
              thousandSeparator={' '}
              placeholder={isPercent ? '%' : 'К'}
              decimalSeparator=","
              decimalScale={isPercent ? 13 : 15}
              suffix={suffix}
              onClear={() => setFieldValue(field.name, '')}
            />
            <PopperComponent message={meta.error} refItem={refFot.current} />
          </>
        )}
      </Field>
      <Field
        name={`${itemKey}.mim`}
        validateOnChange={true}
        validate={validate}>
        {({
          field,
          meta
        }: FieldProps<OrNull<number> | string, IParametersForm>) => (
          <>
            <NumericFormat
              {...field}
              value={getValueParameters(field.value, isPercent)}
              onChange={(e) => {
                updateValue({
                  actID: actId,
                  isGlobal: false,
                  rowID: row.rowID,
                  key: 'mim',
                  value: setValueParameters(e.target.value, isPercent)
                });
              }}
              InputProps={{
                disabled: isDisabledRest,
                endAdornment: null,
                ref: refMim
              }}
              customInput={StyledClearableTextField}
              placeholder={isPercent ? '%' : 'К'}
              suffix={suffix}
              allowNegative={false}
              thousandSeparator={' '}
              decimalSeparator=","
              decimalScale={isPercent ? 13 : 15}
              onClear={() => setFieldValue(field.name, '')}
            />
            <PopperComponent message={meta.error} refItem={refMim} />
          </>
        )}
      </Field>
      <Field
        name={`${itemKey}.mtr`}
        validateOnChange={true}
        validate={validate}>
        {({
          field,
          meta
        }: FieldProps<OrNull<number> | string, IParametersForm>) => (
          <>
            <NumericFormat
              {...field}
              value={getValueParameters(field.value, isPercent)}
              onChange={(e) => {
                updateValue({
                  actID: actId,
                  isGlobal: false,
                  rowID: row.rowID,
                  key: 'mtr',
                  value: setValueParameters(e.target.value, isPercent)
                });
              }}
              InputProps={{
                disabled: isDisabledRest,
                endAdornment: null,
                ref: refMtr
              }}
              customInput={StyledClearableTextField}
              placeholder={isPercent ? '%' : 'К'}
              allowNegative={false}
              thousandSeparator={' '}
              decimalSeparator=","
              decimalScale={isPercent ? 13 : 15}
              suffix={suffix}
              onClear={() => setFieldValue(field.name, '')}
            />
            <PopperComponent message={meta.error} refItem={refMtr.current} />
          </>
        )}
      </Field>
      <Box
        display={'flex'}
        position={'relative'}
        alignItems={'center'}
        justifyContent={'center'}>
        {HelpIcon}
        <ClearIcon
          sx={{ position: 'relative', right: '-15px' }}
          onClick={() => {
            const update = {
              ...getFieldProps(`${itemKey}`).value,
              ...clearData
            };
            setFieldValue(`${itemKey}`, update, true);
          }}
          cursor={'pointer'}
          color={'secondary'}
        />
      </Box>
      {show && (
        <ListRow
          updateValue={updateValue}
          list={row.children}
          itemKey={`${itemKey}.children`}
          actId={actId}
        />
      )}
    </React.Fragment>
  );
};
