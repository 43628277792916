/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import { ICalculationDictionary } from 'api/calculationDictionary';
import { createContext } from 'react';
import type { NavigateOptions } from 'react-router';
import { OrNull } from '../../../../api/references/estimates/estimates.types';
import { ActList, ActResponse } from '../../../../types';

type TInitialTabs = {
  key: string;
  defaultValue?: TTabType;
  navigateOptions?: NavigateOptions;
};

export type TTabType = 'vor' | 'calculation' | 'executed';

export const initialTabs: TInitialTabs = {
  key: 'tab',
  defaultValue: 'calculation',
  navigateOptions: {
    replace: true
  }
};

export type IRemovedIds = { type: string; id: number; parent: number | null }[];
type THandbookContext = {
  buttonContainer: OrNull<HTMLDivElement>;
  openUploadDrawer: (f?: boolean) => void;
  currentAct: OrNull<ActList>;
  changeAct: (act: OrNull<ActList>) => void;
  actList: OrNull<ActResponse>;
  toggleIntegrate: (f: boolean) => void;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  anchorEl: HTMLElement | null;
  parametersDialogOpen: boolean;
  setParametersDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  createDrawer: boolean;
  setCreateDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  removedIds: IRemovedIds;
  setRemovedIds: React.Dispatch<React.SetStateAction<IRemovedIds>>;
  selectedRow: ICalculationDictionary | null;
  setSelectedRow: React.Dispatch<
    React.SetStateAction<ICalculationDictionary | null>
  >;
  deleteRatesWithPositions: () => void;
  openDeleteRateConfirm: (...params: any) => void;
};

export const HandbookContext = createContext<THandbookContext>({
  buttonContainer: null,
  toggleIntegrate: () => {},
  openUploadDrawer: () => {},
  changeAct: () => {},
  currentAct: null,
  actList: null,
  setAnchorEl: () => {},
  anchorEl: null,
  parametersDialogOpen: false,
  setParametersDialogOpen: () => {},
  createDrawer: false,
  setCreateDrawer: () => {},
  removedIds: [],
  setRemovedIds: () => {},
  selectedRow: null,
  setSelectedRow: () => {},
  deleteRatesWithPositions: () => {},
  openDeleteRateConfirm: () => {}
});
