import { Box, Menu, MenuItem } from '@mui/material';
import { LevelFolder } from 'components/LevelFolder';
import { useTreeNodeContext } from 'pages/FullVor/Table/components/TreeNodeRow/context';
import { FC, MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { StyledMenuButton } from './LevelSelect.styles';
import { LevelSelectProps } from './LevelSelect.types';

export const LevelSelect: FC<LevelSelectProps> = ({
  selectedLevel,
  hideLevelsMore,
  onSetLevel,
  type,
  neighborIds,
  currentLevel,
  isEdit
}): JSX.Element => {
  const { level, levelIds, hasParent, maxDeep, groupIds, rowID } =
    useTreeNodeContext();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const saveSelected = useRef<number | undefined>();

  const listItemClickHandler = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const menuItemClickHandler = (level: number) => {
    onSetLevel(level);
    setAnchorEl(null);
  };

  const closeMenuHandler = () => {
    setAnchorEl(null);
  };
  const list: number[] = useMemo(() => {
    // if (isEdit && neighborIds) {
    // const selects = [selectedLevel - 1, selectedLevel, selectedLevel + 1];
    const selects = Array(currentLevel >= 14 ? 14 : (currentLevel || 0) + 1)
      .fill(0)
      .map((v, index) => index + 1);

    // console.log(neighborIds, rowID, groupIds);
    // if (hideLevelsMore || neighborIds[0] === rowID) {
    //   selects.pop();
    // }
    return selects;
    // }
    // if (selectedLevel === 14) return [14];
    // if (selectedLevel === 1 && !hasParent) return [1];
    // if (type === 'group') {
    //   return [selectedLevel - 1, selectedLevel];
    // } else {
    //   return [selectedLevel, selectedLevel + 1];
    // }
  }, [
    type,
    selectedLevel,
    hasParent,
    isEdit,
    hideLevelsMore,
    groupIds,
    neighborIds,
    currentLevel
  ]);
  useEffect(() => {
    console.log(saveSelected.current && list.length);
    if (!saveSelected.current && selectedLevel) {
      saveSelected.current = selectedLevel;
    }
  }, [selectedLevel, saveSelected.current, list]);
  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <StyledMenuButton onClick={listItemClickHandler}>
        <LevelFolder size="large" level={selectedLevel} />
      </StyledMenuButton>
      <Menu anchorEl={anchorEl} open={open} onClose={closeMenuHandler}>
        {/* <MenuItem
          selected={selectedLevel === 1}
          onClick={() => menuItemClickHandler(1)}>
          <LevelFolder level={1} />
        </MenuItem> */}
        {list.map((key) => (
          <MenuItem
            key={key}
            selected={+key === selectedLevel}
            onClick={() => menuItemClickHandler(+key)}>
            <LevelFolder level={+key} />
          </MenuItem>
        ))}
        {/* {Object.keys(levelIds)
          .filter((key) => (hideLevelsMore ? +key < level : true))
          .filter((key) => +key <= 13)
          .map((key) => (
            <MenuItem
              key={key}
              selected={+key + 1 === selectedLevel}
              onClick={() => menuItemClickHandler(+key + 1)}>
              <LevelFolder level={+key + 1} />
            </MenuItem>
          ))} */}
      </Menu>
    </Box>
  );
};
